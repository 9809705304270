import React from "react"

import Metal from '../../components/metal'

import Hakusi from '../../layouts/hakusi'


export default () => (


<Hakusi>

<div className="pad butinuki"/>

<section class="kanaami butinuki t-center">

<Metal/>

<h2 class='moji_kage_koi'>ELEMENTS 必須知識編【限定版】</h2>

<h3 class='moji_kage_koi'>銀行振込（一括払いのみ） 手数料はご負担ください</h3>

<p>４９８００円（税込）</p>

<div id="mc_embed_signup">

	<form action="https://tokyo.us18.list-manage.com/subscribe/post?u=0d71500e8a0f577cff8987305&amp;id=61eb7d0abf" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate>

	    <div id="mc_embed_signup_scroll">

			<div class="mc-field-group">

							<p className='moji_kage_koi t-small t-bold e_red2' style={{ marginTop: `5em` }}>※ メールアドレスが間違っていると商品が受け取れません</p>

				<label for="mce-EMAIL">メールアドレス </label>
				<input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required placeholder='メールアドレス'/>
			</div>

			<div class="mc-field-group">
				<label for="mce-SEI">姓 </label>
				<input type="text" name="SEI" class="required email" id="mce-SEI" required placeholder='姓'/>
			</div>

			<div class="mc-field-group">
				<label for="mce-MEI">名 </label>
				<input type="text" name="MEI" class="required email" id="mce-MEI" required placeholder='名'/>
			</div>

			<div class="mc-field-group">
				<label for="mce-HURIGANA">フリガナ（姓名） </label>
				<input type="text" name="HURIGANA" class="required email" id="mce-HURIGANA" required placeholder='フリガナ（姓名）'/>
			</div>




	        <div class="mc-field-group input-group">
			    <input checked type="radio" style={{display:`none`}} value="ELEMENTS 必須知識編【限定版】" name="SYOUHIN" id="mce-SYOUHIN-0"/>{/*ELEMENTS 必須知識編【限定版】*/}
				<input type="radio" style={{display:`none`}} value="ELEMENTS 必須知識編" name="SYOUHIN" id="mce-SYOUHIN-1"/>{/*ELEMENTS 必須知識編*/}
			</div>

	        <div class="mc-field-group input-group">
			    <input checked type="radio" style={{display:`none`}} value="49800円" name="DAIKIN" id="mce-DAIKIN-0"/>{/*ELEMENTS 49800円*/}
			</div>

<div class="mc-field-group input-group">
</div>

			<div id="mce-responses" class="clear">
				<div class="response" id="mce-error-response" style={{display:`none`}}></div>
				<div class="response" id="mce-success-response" style={{display:`none`}}></div>
			</div>

		    <div style={{position: `absolute`, left: -5000}} aria-hidden="true">
		    	<input type="text" name="b_0d71500e8a0f577cff8987305_61eb7d0abf" tabindex="-1" value=""/>
		    </div>

		    <div class="clear">
							<input type="submit" value="注文する" name="subscribe" id="mc-embedded-subscribe" class="button" />
							<p className='t-small'>！　ご注文後のキャンセルはできません</p>
		    </div>

	    </div>
	</form>
</div>

			<p className='t-left moji_kage_koi t-bold' style={{ marginTop: `2em`,marginLeft:`10vw`,marginRight:`1em` }}>
				ご注文をいただくと、数分から数時間以内に<br/>
				振込先をご案内するメールが自動で届きます。<br />
				<br />
				その後、銀行の３営業日以内に<br />
				お振込を確認できれば、<br />
				<br/>
				・教材のURL<br/>
				・教材の使用に必要なライセンスキー<br/>
				<br/>
				を記載したメールをお送りいたします。<br />
				<br/>
				お振込の確認は<br/>
				手作業での確認となりますが、<br/>
				基本的には数時間以内、<br />
				どれほど遅くても１営業日以内には<br />
				商品をお送りしています。<br />
				<br/>
				なお、週末などで銀行が休日の場合は、<br />
				お振込が確認できるまで<br/>
				数日かかる場合がございます。<br />
</p>


</section>

{/* <div className="pad butinuki"/> */}


</Hakusi>
)
